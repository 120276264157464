import request from '@/utils/request'

export function getAllEmployees(data) {
  return request({
    url: '/work/statistics/corp_user_list',
    method: 'post',
    data,
  })
}

export function getBookingStatisticsList(data) {
  return request({
    url: '/work/statistics/order_list',
    method: 'post',
    data,
  })
}