<template>
	<div class="table-content">
		<div class="search-bar">
			<a-range-picker
				allowClear
				value-format="YYYY-MM-DD"
				v-model="date"
				class="search-bar-input" />
			<a-select
				v-model="type"
				allowClear
				class="search-bar-input"
				placeholder="渠道">
				<a-select-option
					v-for="item in typeList"
					:key="item.id"
					:value="item.id">
					{{ item.name }}
				</a-select-option>
			</a-select>
			<template>
				<a-select
					v-if="type === 1"
					v-model="openid"
					allowClear
					class="search-bar-input"
					placeholder="请选择员工">
					<a-select-option
						v-for="item in employeelist"
						:key="item.openid"
						:value="item.openid">
						<template v-if="item.nickname">{{
							item.nickname
						}}</template>
						<ww-open-data
							v-else
							type="userName"
							:openid="item.openid">
						</ww-open-data>
					</a-select-option>
				</a-select>
				<!-- <a-input
					v-if="type === 1"
					v-model="nickname"
					placeholder="请输入微信昵称"
					allowClear
					class="search-bar-input" />
				<a-select
					v-else-if="type === 2"
					v-model="userid"
					allowClear
					class="search-bar-input"
					placeholder="请选择餐厅销售">
					<a-select-option
						v-for="item in employeelist"
						:key="item.userid"
						:value="item.userid">
						<template v-if="item.name">{{ item.name }}</template>
						<ww-open-data
							v-else
							type="userName"
							:openid="item.userid"></ww-open-data>
					</a-select-option>
				</a-select> -->
			</template>
			<a-button type="primary" @click="getList()">搜索</a-button>
			<a-button
				type="primary"
				ghost
				style="margin-left: 30px"
				@click="getList(1, 1)"
				>导出</a-button
			>
		</div>
		<a-table
			:columns="columns"
			:data-source="list"
			rowKey="id"
			:pagination="pagination"
			@change="handleTableChange">
			<!--下单渠道；1：微信下单，2：企业微信下单；3：前台下单-->
			<!-- <template #channel="record">
				<div
					class="overflow-ellipsis overflow-hidden whitespace-nowrap"
					:title="record.with_mini_user.nickname"
					v-if="record.channel === 1 && record.with_mini_user">
					{{ record.sales_name || record.with_mini_user.nickname }}
				</div>
				<div
					v-else-if="record.channel === 2"
					:title="
						record.with_corp_user.name ||
						record.with_corp_user.userid
					">
					<template v-if="record.with_corp_user.name">
						{{ record.sales_name || record.with_corp_user.name }}
					</template>
					<template v-else>
						<ww-open-data
							type="userName"
							:openid="record.with_corp_user.userid" />
					</template>
				</div>
				<template v-else-if="record.channel === 3">前台预订</template>
			</template> -->
		</a-table>
	</div>
</template>

<script>
import { getBookingStatisticsList } from '@/api/bookingStatistics'
import { getEmployeeList } from '@/api/employee'
import storage from 'store'

export default {
	name: 'BookingStatistics',
	data() {
		return {
			date: null,
			typeList: [
				{ id: 1, name: '移动工作台' },
				{ id: 2, name: '餐厅销售' },
				{ id: 3, name: '前台预订' }
			],
			type: undefined,
			userid: undefined,
			openid: undefined,
			nickname: '',
			employeelist: [],
			list: [],
			columns: [
				{
					title: '订单id',
					dataIndex: 'id'
				},
				{
					title: '渠道',
					dataIndex: 'mini_user_openid',
					customRender: col => (col ? '移动工作台' : '前台预订')
				},
				{
					title: '员工',
					dataIndex: 'with_mini_bind_user.nickname',
					customRender: col => (col ? col : '无')
				},
				{
					title: '预订人',
					dataIndex: 'name',
					customRender: (name, record) =>
						`${name}${
							record.sex === 1
								? '先生'
								: record.sex === 2
								? '女士'
								: ''
						}`,
					ellipsis: true
				},
				{
					title: '预订电话',
					dataIndex: 'phone',
					customRender: (name, record) =>
						record.phone ? record.phone : '无'
				},
				{
					title: '预订时间',
					dataIndex: 'order_date'
				},
				{
					title: '预计到店时间',
					dataIndex: 'order_time'
				},
				{
					title: '预计到店人/桌数',
					dataIndex: 'number',
					customRender: (name, record) =>
						record.unit === '桌'
							? `${record.number}桌`
							: `${record.number}人`
				},
				{
					title: '就餐类型',
					dataIndex: 'order_type',
					customRender: _col => {
						return [
							'早餐',
							'午餐',
							'晚餐',
							'夜宵',
							'午餐二轮',
							'晚餐二轮'
						][_col]
					}
				},
				{
					title: '餐位名',
					dataIndex: 'with_room.title'
				},
				{
					title: '提交时间',
					dataIndex: 'created_at'
				},
				{
					title: '销售额(元）',
					dataIndex: 'money'
				},
				{
					title: '渠道提成(元）',
					dataIndex: 'allowance'
				},
				{
					title: '订单状态',
					dataIndex: 'status',
					customRender: (_col, record) => {
						if (record.deleted_at) return '已取消'
						else return ['预订中', '已结算清台', '客未到清台'][_col]
					}
				},
				{
					title: '是否确认',
					dataIndex: 'confirm',
					customRender: col => (col === 1 ? '已确认' : '未确认')
				},
				{
					title: '备注',
					dataIndex: 'remark.text'
				},
				{
					title: '回访记录',
					dataIndex: 'remark_finish'
				}
			],
			pagination: {
				current: 1,
				defaultPageSize: 15,
				showTotal: total => `共${total}条`
			}
		}
	},
	mounted() {
		// 如果没有设置绩效则不展示最后两列
		if (
			this.$store.state.user.info.shop &&
			!this.$store.state.user.info.shop.commission > 0
		) {
			this.columns.splice(this.columns.length - 5, 2)
		}
		if (
			this.$store.state.user.info.shop &&
			!this.$store.state.user.info.shop.corpid
		) {
			this.typeList.splice(1, 1)
		}
		this.getAllEmployeeList()
		this.getList()
	},
	watch: {
		type(val) {
			if (val === 1 && this.openid) {
				this.openid = undefined
			} else if (val === 2 && this.nickname) {
				this.nickname = undefined
			}
		}
	},
	methods: {
		getAllEmployeeList() {
			getEmployeeList().then(res => {
				if (res.code === 0) {
					this.employeelist = res.data
				}
			})
		},
		getList(page = 1, exportNum = false) {
			this.$store.dispatch('loading/startContentLoading')
			// 创建参数对象
			const params = {
				page,
				type: this.type,
				nickname: this.nickname,
				userid: this.userid,
				start_time: this.date && this.date[0],
				end_time: this.date && this.date[1],
				openid: this.openid
			}
			// 剔除空值参数
			for (const key in params) {
				if (!params[key]) delete params[key]
			}
			// 是否为导出
			if (exportNum === 1) {
				if (this.pagination.total >= 1000) {
					this.$store.dispatch('loading/endContentLoading')
					this.$message.error(
						'您同时选择的数据过多，单次导出不可超过1000条。请再进行日期和渠道筛选后再进行导出操作。'
					)
					return
				}
				params.export = exportNum
				params.token = storage.get('token')
				let url = new URLSearchParams(params)
				url =
					process.env.VUE_APP_API_BASE_URL +
					'/work/statistics/order_list?' +
					url
				const link = document.createElement('a')

				link.href = url
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)

				this.$store.dispatch('loading/endContentLoading')
			} else {
				getBookingStatisticsList(params).then(res => {
					if (res.code === 0) {
						const pagination = { ...this.pagination }
						pagination.total = res.data.total
						this.list = res.data.data
						this.pagination = pagination
					}
					this.$store.dispatch('loading/endContentLoading')
				})
			}
		},
		handleTableChange(pagination) {
			const pager = { ...this.pagination }
			pager.current = pagination.current
			this.pagination = pager
			this.getList(pagination.current)
		}
	}
}
</script>

<style scoped lang="less">
.search-bar {
	display: flex;
	flex-wrap: wrap;
}
.search-bar-input {
	width: 320px;
	margin-right: 25px;
	margin-bottom: 20px;
}
</style>
